import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import {ZuEventType, ZuPoint, zuEventTypeDisplayName, ZuSignalSource, ZuOnOffReason} from '../lib/ZuPoint';
import MenuItem from "@material-ui/core/MenuItem";


export default function ZuPointEditorDialog({event, onSave, onCancel}) {
    const [type, setType] = React.useState(event.type);

    const [latitude, setLatitude] = React.useState(event.coord[0]);
    const [longitude, setLongitude] = React.useState(event.coord[1]);
    const [source, setSource] = React.useState(event.source || ZuSignalSource.gsm);
    const [reason, setReason] = React.useState(event.reason || ZuOnOffReason.accelerometer);

    function cancel() {
        if (onCancel) onCancel();
    }

    function save() {if (onSave) onSave(new ZuPoint({
            type: type,
            coord: [latitude, longitude],
            source: (type===ZuEventType.signal || type===ZuEventType.noSignal)?source:undefined,
            reason: (type===ZuEventType.on || type===ZuEventType.off)?reason:undefined
        }));
    }

    return (
             <Dialog open={!!event} onClose={cancel} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Настройка маршрутной точки</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Широта"
                        fullWidth
                        value={latitude}
                        onChange={(e)=>setLatitude(e.target.value)}
                    />
                    <TextField
                        margin="dense"
                        label="Долгота"
                        fullWidth
                        value={longitude}
                        onChange={(e)=>setLongitude(e.target.value)}
                    />
                    <TextField
                        select
                        value={type}
                        margin="dense"
                        fullWidth
                        onChange={(e)=>setType(e.target.value)}
                        label="Тип события"
                    >
                        <MenuItem value={ZuEventType.coord}>{zuEventTypeDisplayName(ZuEventType.coord)}</MenuItem>
                        <MenuItem value={ZuEventType.on}>{zuEventTypeDisplayName(ZuEventType.on)}</MenuItem>
                        <MenuItem value={ZuEventType.off}>{zuEventTypeDisplayName(ZuEventType.off)}</MenuItem>
                        <MenuItem value={ZuEventType.signal}>{zuEventTypeDisplayName(ZuEventType.signal)}</MenuItem>
                        <MenuItem value={ZuEventType.noSignal}>{zuEventTypeDisplayName(ZuEventType.noSignal)}</MenuItem>
                        <MenuItem value={ZuEventType.lowBattery}>{zuEventTypeDisplayName(ZuEventType.lowBattery)}</MenuItem>
                        <MenuItem value={ZuEventType.removed}>{zuEventTypeDisplayName(ZuEventType.removed)}</MenuItem>
                    </TextField>
                    {(type===ZuEventType.signal || type===ZuEventType.noSignal)?
                        <TextField
                            select
                            value={source}
                            margin="dense"
                            fullWidth
                            onChange={(e) => setSource(e.target.value)}
                            label="Источник"
                        >
                            <MenuItem value={ZuSignalSource.gsm}>{ZuSignalSource.gsm}</MenuItem>
                            <MenuItem value={ZuSignalSource.sat}>{ZuSignalSource.sat}</MenuItem>
                        </TextField>
                        :null
                    }
                    {(type===ZuEventType.on || type===ZuEventType.off)?
                        <TextField
                            select
                            value={reason}
                            margin="dense"
                            fullWidth
                            onChange={(e) => setReason(e.target.value)}
                            label="Причина"
                        >
                            <MenuItem value={ZuOnOffReason.accelerometer}>{ZuOnOffReason.accelerometer}</MenuItem>
                            <MenuItem value={ZuOnOffReason.power}>{ZuOnOffReason.power}</MenuItem>
                        </TextField>
                        :null
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={save} color="primary">
                        Сохранить
                    </Button>
                    <Button onClick={cancel} color="secondary">
                        Отменить
                    </Button>
                </DialogActions>
            </Dialog>
    );
}