
import React, {useState} from 'react';

import {
    GroupingState,
    IntegratedGrouping,
    IntegratedPaging,
    IntegratedSorting,
    PagingState, SortingState
} from '@devexpress/dx-react-grid';
import {Grid, Table, TableHeaderRow, PagingPanel, TableColumnVisibility, TableColumnResizing, ColumnChooser, Toolbar, GroupingPanel, TableGroupRow } from '@devexpress/dx-react-grid-material-ui';

let columns=[
    { name: 'deviceId', title: 'Id устройства' },
    { name: 'time', title: 'Время' },
    { name: 'type', title: 'Событие' },
    { name: 'latitude', title: 'Широта' },
    { name: 'longitude', title: 'Долгота' },
    { name: 'nmea', title: 'NMEA' },
    { name: 'source', title: 'Cигнал'},
    { name: 'reason', title: 'Причина'},
    { name: 'battery', title: 'Заряд'},
    { name: 'period', title: 'Период'}
];

let columnWidths=[
    //{ columnName: 'id', width: 70 },
    { columnName: 'time', width: 200 },
    { columnName: 'deviceId', width: 100 },
    { columnName: 'type', width: 100 },
    { columnName: 'latitude', width: 100 },
    { columnName: 'longitude', width: 100 },
    { columnName: 'nmea', width: 550 },
    { columnName: 'source', width: 100},
    { columnName: 'reason', width: 150 },
    { columnName: 'battery', width: 100},
    { columnName: 'period', width: 100}
];

let hiddenColumns=['id', 'time', 'latitude', 'longitude'];

function DcEventsList({events}) {
    const [defaultColumnWidths] = useState(columnWidths);
    const [defaultHiddenColumnNames] = useState(hiddenColumns);


    function getLinearEvents() {
        let linear=[];
        for (let key of Object.keys(events)) {
            linear=[...linear, ...events[key]];
        }
        return linear;
    };

    return (
        <Grid rows={getLinearEvents()} columns={columns}>

                <SortingState
                    defaultSorting={[]}
                />
                <GroupingState
                    defaultGrouping={[{columnName: 'deviceId'}]}
                    defaultExpandedGroups={[]}
                />

            <PagingState
                defaultCurrentPage={0}
            />

                <IntegratedGrouping />
                <IntegratedSorting />

            <IntegratedPaging />
            <Table />
            <TableColumnResizing defaultColumnWidths={defaultColumnWidths} />
            <TableHeaderRow />
            <TableColumnVisibility
                defaultHiddenColumnNames={defaultHiddenColumnNames}
            />
            <TableGroupRow />
            <Toolbar />
            <GroupingPanel showSortingControls={false}/>
            <ColumnChooser />
            <PagingPanel pageSizes={[10, 20, 30, 0]} />
        </Grid>
    )
}

export default DcEventsList;
