import {ZuPacket} from "./ZuPacket";


function fireEvent(fnc, ...args) {
    if (fnc) fnc(...args);
}


export class DcWsConnection {
    constructor({url}) {
        this.url=url;
        this.socket=null;
        this.connected=false;
        this.onConnectedChange=null;
        this.onMessage=null;

        this.connect=this.connect.bind(this);
        this.send=this.send.bind(this);
    }


    send(data) {
        if (this.connected) return this.socket.send(data);
        return false;
    }

    connect() {
        this.socket = new window.WebSocket(this.url);
        this.socket.onopen = () => {
            this.connected=true;
            fireEvent(this.onConnectedChange, this.connected);
            console.log('ws connected');
        };
        this.socket.onclose = (event) => {
            this.connected=false;
            if (event.wasClean) {
            } else {
            }
            fireEvent(this.onConnectedChange, this.connected);
            console.log(`ws disconnected: code: ${event.code} причина: ${event.reason}`);

            setTimeout(this.connect, 1000);
        };


        this.socket.onmessage = (event) => {
            try {
                let eventObj=JSON.parse(event.data);
                let packet=new ZuPacket({});
                switch (eventObj.type) {
                    case 'json':
                        packet.fromObject(eventObj.json);
                        break;
                    case 'plainText':
                        packet.fromPlainText(eventObj.plainText);
                        break;
                }
                fireEvent(this.onMessage, packet)
            } catch (e) {
                console.log(`ws cannot parse event ${event}`);
            }
        };
        /*
        this.socket.onmessage = (event) => {
            try {
               let eventObj=JSON.parse(event.data);
                fireEvent(this.onMessage, eventObj);
            } catch (e) {
                console.log(`ws cannot parse event ${event}`);
            }
        };
        */
        this.socket.onerror = (error) => {
            console.log(`ws connection error: ${error.message}`);
        };
    }
}