import React from 'react';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';

import { YMaps, Map, Polyline } from 'react-yandex-maps';
import AlertDialog from "./AlertDialog";
import {ZuEvent, ZuEventType, ZuPoint} from '../lib/ZuPoint';
import {ZuState} from '../lib/ZuState';
import ZuPointEditorDialog from "./ZuPointEditorDialog";
import ZuIdEditorDialog from "./ZuIdEditorDialog";
import ZuAnimationLines from "./ZuAnimationLines";
import ZuPlacemarks from "./ZuPlacemarks";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import ZuEventsList from "./ZuEventsList";
import zuEventListStorage from '../stores/zuEventListStore'
import * as zuEventListActions from '../actions/zuEventListActions';

import config from '../config';

import {ZuEventSender, ZuJsonEventSender, ZuJsonEventSender2} from '../lib/ZuEventSender';
import {downloadAsFile} from "../lib/download";
import {randomString} from "../lib/randomString";
import {ZuStateComponent} from "./ZuStateComponent";
import ZuSendTargetDialog from "./ZuSendTargetDialog";

class Zu extends React.Component {
	constructor(props) {
		super(props);
		this.state={
			isYmapsEditing: false,
			zuId: randomString(7),
			points: [],
			currentPosition: null,
			ymapsLoaded: false,
			alertText: null,
			alertTitle: null,
			editPointIndex: null,

			isAnimating: false,
			animationPaused: false,
			animationCounter: 0,

			coordEventInterval: 1000,
			sendEventsInterval: 5000,
			battery: 100,
			maxEventsInPacket: 1000,
			speed: 250,

			packetType: 'plainText',
			sendType: 'direct',
			sendUrl: ''
		};
		this.onYMapsLoad=this.onYMapsLoad.bind(this);
		this.editMapClickHandler=this.editMapClickHandler.bind(this);
		this.clearPoints=this.clearPoints.bind(this);
		this.savePoints=this.savePoints.bind(this);
		this.loadPoints=this.loadPoints.bind(this);
		this.startAnimation=this.startAnimation.bind(this);
		this.stopAnimation=this.stopAnimation.bind(this);
		this.pauseAnimation=this.pauseAnimation.bind(this);
		this.resumeAnimation=this.resumeAnimation.bind(this);

		this.setSpeed=this.setStateIntFieldValue.bind(this, 'speed');
		this.setCoordEventInterval=this.setStateIntFieldValue.bind(this, 'coordEventInterval');
		this.setSendEventsInterval=this.setStateIntFieldValue.bind(this, 'sendEventsInterval');
		this.setBattery=this.setStateIntFieldValue.bind(this, 'battery');

		this.animationLoopInterval=500;
		this.animationLines=[];
		this.animationTimer=null;
		this.animationZuState=new ZuState();

		//refs
		this.ymaps=null;
		this.ymap=null;
		this.polyline=null;

		this.eventSender=new ZuJsonEventSender2({
			dataUrl: config.zu.url["zu-api-json"],
			deviceId: this.state.zuId,
			battery: 100,
			period: Math.round(this.state.sendEventsInterval/1000)
		});
		this.eventSender.packetType=this.state.packetType;
		this.eventSender.sendType=this.state.sendType;
		this.eventSender.sendUrl=this.state.sendUrl;

		navigator.geolocation.getCurrentPosition((position)=> {
			this.setCurrentPosition([position.coords.latitude, position.coords.longitude])
		});
	}

	setStateFieldValue(fieldName, e) {
		this.setState({[fieldName]: e.target.value});
	}

	setStateIntFieldValue(fieldName, e) {
		this.setState({[fieldName]: parseInt(e.target.value)});
		if (fieldName==='battery') this.eventSender.battery=this.state.battery;
		if (fieldName==='sendEventsInterval') this.eventSender.period=Math.round(this.state.sendEventsInterval/1000);
	}
	setStateFieldChecked(fieldName, e) {
		this.setState({[fieldName]: e.target.checked});
	}

	onYMapsLoad(ymaps) {
		window.ymaps=this.ymaps=ymaps;

		this.setState({ymapsLoaded: true});
		if (this.state.currentPosition) {
			this.ymap.setCenter(this.state.currentPosition, config.zu.ui.ymaps.default_zoom, {duration: config.zu.ui.ymaps.center_duration});
		}
	}

	setCurrentPosition(coord) {
		this.setState({currentPosition: coord});
		if (this.state.ymapsLoaded) {
			this.ymap.setCenter(coord, config.zu.ui.ymaps.default_zoom, {duration: config.zu.ui.ymaps.center_duration});
		}
	}

	clearPoints() {
		this.setState({points: []});
	}

	savePoints() {
		let config={
			zuId: this.state.zuId,
			points: this.state.points,
			version: '3',

			coordEventInterval: this.state.coordEventInterval,
			sendEventsInterval: this.state.sendEventsInterval,
			battery: this.state.battery,
			maxEventsInPacket: this.state.maxEventsInPacket,
			speed: this.state.speed,

			packetType: this.state.packetType,
			sendType: this.state.sendType,
			sendUrl: this.state.sendUrl
		};
		downloadAsFile(config, 'routes.json');
	}

	loadPoints() {
		let file=this.uploadInput.files[0];
		let reader=new FileReader();
		reader.onload= () => {
			var text=reader.result;
			try {
				let config=JSON.parse(text);
				let zuId=config.zuId;
				let points=config.points;
				if (points.length) {
					let bounds=points.reduce( (newBounds, point)=> {
						return [
							[Math.min(newBounds[0][0], point.latitude), Math.min(newBounds[0][1], point.longitude)],
							[Math.max(newBounds[1][0], point.latitude), Math.max(newBounds[1][1], point.longitude)]
						];
					}, [[1000,1000], [-1000, -1000]]);
					this.ymap.setBounds(bounds);
				}

				this.setState({
					zuId: zuId,
					points: points,
					coordEventInterval: config.coordEventInterval,
					sendEventsInterval: config.sendEventsInterval,
					battery: config.battery,
					maxEventsInPacket: config.maxEventsInPacket,
					speed: config.speed,
					packetType: config.packetType,
					sendType: config.sendType,
					sendUrl: config.sendUrl
				});
				this.eventSender.deviceId=zuId;
				this.eventSender.packetType=config.packetType;
				this.eventSender.sendType=config.sendType;
				this.eventSender.sendUrl=config.sendUrl;
			} catch (e) {
				this.setState({alertText: `Ошибка загрузки маршрута из ${file.name} - файл имеет неверную структуру`, alertTitle: 'Загрузка маршрута'});
			}
		};
		reader.readAsText(file);
	}

	editMapClickHandler() {
		let polyline=this.polyline;
		if (this.state.isYmapsEditing) {
			let newPoints=[];
			for (let t=0;t<polyline.geometry.getLength();t++) {
				let coords=polyline.geometry.get(t);
				let point=new ZuPoint({coord: coords});
				newPoints.push(point);
			}
			polyline.editor.stopEditing();
			this.setState({isYmapsEditing: false, points: newPoints});
		} else {
			this.setState({isYmapsEditing: true});
			polyline.editor.startEditing().then( () => {
				polyline.editor.startDrawing();
			});
		}
	}

	startAnimation() {
		if (this.animationTimer) return;
		if (this.state.isAnimating) return;

		this.animationLines = [];
		this.state.points.forEach((point, index) => {
			if (index) this.animationLines.push([]);
		});
		this.setState({isAnimating: true, animationPaused: false});

		zuEventListStorage.dispatch(zuEventListActions.stopNotSent());
		this.eventSender.reset();
		this.eventSender.start();

		const getDistance=(coord1, coord2) => {
			return Math.sqrt(
				Math.pow((coord2[0] - coord1[0]), 2) +
				Math.pow((coord2[1] - coord1[1]), 2)
			);
		};

		const generateSmoothCoords=(coords, distanceInterval) => {
			let smoothCoords = [];
			smoothCoords.push(coords[0]);
			for (let i = 1; i < coords.length; i++) {
				let difference = [coords[i][0] - coords[i - 1][0], coords[i][1] - coords[i - 1][1]];
				let maxAmount = Math.max(Math.abs(difference[0] / distanceInterval), Math.abs(difference[1] / distanceInterval));
				let minDifference = [difference[0] / maxAmount, difference[1] / maxAmount];
				let lastCoord = coords[i - 1];
				while (maxAmount > 1) {
					lastCoord = [lastCoord[0] + minDifference[0], lastCoord[1] + minDifference[1]];
					smoothCoords.push(lastCoord);
					maxAmount--;
				}
				smoothCoords.push(coords[i])
			}
			return smoothCoords;
		};


		//let zuState=new ZuState();
		this.animationZuState.reset();
		let remainToCoordEvent=this.state.coordEventInterval;
		let remainToSendEvents=this.state.sendEventsInterval;
		let isFirstCoord=true;

		const generateSystemEvents=(event) => {
			if (event.type!==ZuEventType.coord) {
				zuEventListStorage.dispatch(zuEventListActions.addZuEvent(event));
			}
			//if (point.noSignalSat) zuEventListStorage.dispatch(zuEventListActions.addNoSignalEvent({source: 'satellite'}));
			//if (point.noSignalGsm) zuEventListStorage.dispatch(zuEventListActions.addNoSignalEvent({source: 'gsm'}));
			//if (point.power) zuEventListStorage.dispatch(zuEventListActions.addPowerEvent({type:point.power, reason: point.reason}));
			this.animationZuState.updateState(event);
		};

		const generateCoordEvent=(currentCoords) => {
			remainToCoordEvent=remainToCoordEvent-this.animationLoopInterval;
			if (remainToCoordEvent<=0 || isFirstCoord) {
				remainToCoordEvent=this.state.coordEventInterval;

				if (this.animationZuState.isPowerOn) {
					if (!this.animationZuState.noSignalSat) {
						zuEventListStorage.dispatch(zuEventListActions.addZuEvent(new ZuEvent({coord:currentCoords})));
						//zuEventListStorage.dispatch(zuEventListActions.addCoordEvent(currentCoords));
					} else {
					}
				}
				isFirstCoord=false;
			}

			remainToSendEvents=remainToSendEvents-this.animationLoopInterval;
			if (remainToSendEvents<=0) {
				remainToSendEvents=this.state.sendEventsInterval;
				if (!this.animationZuState.noSignalGsm) {
					this.eventSender.send();
				}
			}
		};

		let currentDstPointIndex=0;
		let smoothPoints=[];
		let smoothPointIndex = 0;
		let linePoints;
		let animationCounter=0;

		const loop=()=>{
			if (!this.animationTimer) return;
			if (this.state.animationPaused) return;
			if (smoothPointIndex >= smoothPoints.length) {
				currentDstPointIndex++;
				if (currentDstPointIndex<this.state.points.length) {
					let startPoint = this.state.points[currentDstPointIndex - 1];
					let endPoint = this.state.points[currentDstPointIndex];
					let pointsCoords = [startPoint.coord, endPoint.coord];
					let distance = pointsCoords.reduce((distance, point, index) => {
						if (index === 0) return 0;
						return distance + getDistance(pointsCoords[index - 1], point);
					}, 0);

					let distanceM=this.ymaps.coordSystem.geo.getDistance(startPoint.coord, endPoint.coord);
					let speedKmPerHour=this.state.speed;
					let speedMPerS=speedKmPerHour*1000/60/60;
					let animationInterval = distanceM/speedMPerS*1000;

					generateSystemEvents(startPoint);
					smoothPoints = generateSmoothCoords(pointsCoords, distance / animationInterval * this.animationLoopInterval);
					linePoints=this.animationLines[currentDstPointIndex-1];
					smoothPointIndex = 0;
				} else {
					this.eventSender.send();
				}
			}
			if (smoothPointIndex < smoothPoints.length) {
				generateCoordEvent(smoothPoints[smoothPointIndex]);

				linePoints.push(smoothPoints[smoothPointIndex]);
				this.setState({animationCounter: animationCounter++});
				smoothPointIndex++;
			} else {
				generateSystemEvents(this.state.points[this.state.points.length-1]);
				this.eventSender.send();
				this.eventSender.stop();

				this.setState({isAnimating: false});
				clearInterval(this.animationTimer);
				this.animationTimer=null;
			}
		};
		this.animationTimer=setInterval(loop.bind(this), this.animationLoopInterval);
	}

	stopAnimation() {
		clearInterval(this.animationTimer);
		this.animationTimer=null;
		this.eventSender.stop();
		this.setState({isAnimating: false, animationPaused: false});
	}

	pauseAnimation() {
		this.setState({animationPaused: true});
	}

	resumeAnimation() {
		this.setState({animationPaused: false});
	}

	render () {
		const classes = this.props.classes;

		const buttonsPaperClass=clsx(classes.paper, {
			display: 'flex',
			flexDirection: 'column',
		});
		const buttonClass=clsx({
		});
		//const fixedHeightPaper= clsx(classes.paper, classes.fixedHeight);


		return (
			<Grid container spacing={3}>
				<Grid item xs={12} md={9} lg={9}>
					<Paper>
						<YMaps query={{ lang: 'ru_RU', apikey: 'd9671ec9-8b1f-4000-a1c1-6ba96f87656e' }}>
							<Map
								modules={["package.full", "coordSystem.geo"]}
								onLoad={this.onYMapsLoad}
								instanceRef={inst => { window.ymap=this.ymap=inst } }
								width="100%"
								height="600px"
								defaultState={{
									center: config.zu.ui.ymaps.default_center, zoom: config.zu.ui.ymaps.default_zoom,
									controls: ['zoomControl', 'fullscreenControl', 'geolocationControl', 'rulerControl', 'typeSelector']
								}}
							>
								{
									this.state.isYmapsEditing?
										null:
										<ZuPlacemarks points={this.state.points} onPlacemarkClick={(index)=>{ this.setState({editPointIndex: index});}} />
								}
								<ZuAnimationLines isAnimating={this.state.isAnimating} points={this.state.points} animationLines={this.animationLines} />
								<Polyline
									geometry={[ ...this.state.points.map( (point)=>point.coord ) ]}
									instanceRef={ (inst)=> {this.polyline=inst;}}
									options={{
										strokeColor: "#0066ffff",
										strokeWidth: 1,
										editorMaxPoints: 10000,

										editorMenuManager: (menuItems)=>{
											menuItems.push({
												id: 'myStopEditing',
												title: 'Завершить редактирование',
												onClick: this.editMapClickHandler
											});
											return menuItems;
										}
									}}
								/>
							</Map>
						</YMaps>
					</Paper>
				</Grid>
				<Grid item xs={12} md={3} lg={3}>
					<Paper className={buttonsPaperClass}>
						<ZuIdEditorDialog
							zuId={this.state.zuId}
							openButtonProps={{
								className: buttonClass,
								disabled: !this.state.ymapsLoaded,
								text: `Id устройства: ${this.state.zuId}`,
								variant: "outlined"
							}}
							onSave={(newZuId)=>{
								this.setState({zuId: newZuId});
								this.eventSender.deviceId=newZuId;
							}}
						/>
					</Paper>

					<Paper className={buttonsPaperClass}>
						{/* variant="contained"  */}
						<Button
							className={buttonClass}
							onClick={this.editMapClickHandler}
							disabled={this.state.isAnimating || !this.state.ymapsLoaded}
							color={this.state.isYmapsEditing?"secondary":"primary"}
							variant="contained"
						>
							{this.state.isYmapsEditing?'Завершить':'Редактировать точки'}
						</Button>
						<Button
							className={buttonClass}
							disabled={this.state.isAnimating || this.state.isYmapsEditing || !this.state.points.length}
							onClick={this.clearPoints}
						>
							Очистить
						</Button>
						<Button
							className={buttonClass}
							disabled={this.state.isYmapsEditing || !this.state.points.length}
							onClick={this.savePoints}
						>
							Сохранить
						</Button>
						<input ref={ inst=> { this.uploadInput=inst; } } style={{display: 'none'}} type={'file'} onChange={this.loadPoints} />
						<Button
							className={buttonClass}
							disabled={this.state.isAnimating || !this.state.ymapsLoaded || this.state.isYmapsEditing}
							onClick={ ()=>{
								this.uploadInput.value='';
								this.uploadInput.click()
							} }
						>
							Загрузить
						</Button>
						<AlertDialog alertText={this.state.alertText} alertTitle={this.state.alertTitle} onClose={()=>this.setState({alertText: null})} />
					</Paper>
					<Paper className={buttonsPaperClass}>
						Управление движением
						<TextField select value={this.state.speed} onChange={this.setSpeed} label="Скорость движения">
							<MenuItem value="10">10 км/ч</MenuItem>
							<MenuItem value="20">20 км/ч</MenuItem>
							<MenuItem value="30">40 км/ч</MenuItem>
							<MenuItem value="40">40 км/ч</MenuItem>
							<MenuItem value="60">60 км/ч</MenuItem>
							<MenuItem value="80">80 км/ч</MenuItem>
							<MenuItem value="100">100 км/ч</MenuItem>
							<MenuItem value="120">120 км/ч</MenuItem>
							<MenuItem value="140">140 км/ч</MenuItem>
							<MenuItem value="160">160 км/ч</MenuItem>
							<MenuItem value="180">180 км/ч</MenuItem>
							<MenuItem value="200">200 км/ч</MenuItem>
							<MenuItem value="250">250 км/ч</MenuItem>
							<MenuItem value="500">500 км/ч</MenuItem>
							<MenuItem value="1000">1000 км/ч</MenuItem>
							<MenuItem value="2000">2000 км/ч</MenuItem>
						</TextField>
						<TextField select value={this.state.coordEventInterval} onChange={this.setCoordEventInterval} label="Интервал отсчетов">
							<MenuItem value="1000">1 с</MenuItem>
							<MenuItem value="2000">2 с</MenuItem>
							<MenuItem value="3000">3 с</MenuItem>
							<MenuItem value="4000">4 с</MenuItem>
							<MenuItem value="5000">5 с</MenuItem>
							<MenuItem value="6000">6 с</MenuItem>
							<MenuItem value="7000">7 с</MenuItem>
							<MenuItem value="8000">8 с</MenuItem>
							<MenuItem value="9000">9 с</MenuItem>
							<MenuItem value="10000">10 с</MenuItem>
						</TextField>
						<TextField select value={this.state.sendEventsInterval} onChange={this.setSendEventsInterval} label="Интервал отправки">
							<MenuItem value="5000">5 с</MenuItem>
							<MenuItem value="10000">10 с</MenuItem>
							<MenuItem value="15000">15 с</MenuItem>
							<MenuItem value="20000">20 с</MenuItem>
							<MenuItem value="30000">30 с</MenuItem>
							<MenuItem value="45000">45 с</MenuItem>
							<MenuItem value="60000">60 с</MenuItem>
						</TextField>
						<TextField select value={this.state.battery} onChange={this.setBattery} label="Заряд батареи">
							<MenuItem value="100">100</MenuItem>
							<MenuItem value="90">90</MenuItem>
							<MenuItem value="80">80</MenuItem>
							<MenuItem value="70">70</MenuItem>
							<MenuItem value="60">60</MenuItem>
							<MenuItem value="50">50</MenuItem>
							<MenuItem value="40">40</MenuItem>
							<MenuItem value="30">30</MenuItem>
							<MenuItem value="20">20</MenuItem>
							<MenuItem value="10">10</MenuItem>
							<MenuItem value="0">0</MenuItem>
						</TextField>
						<Button
							className={buttonClass}
							disabled={this.state.isYmapsEditing || (this.state.points.length<2)}
							onClick={this.state.isAnimating?this.stopAnimation:this.startAnimation}
							color={this.state.isAnimating?"secondary":"primary"}
							variant="contained"
						>
							{this.state.isAnimating?'Остановить':'Начать движение'}
						</Button>
						<Button
							className={buttonClass}
							disabled={!this.state.isAnimating}
							onClick={this.state.animationPaused?this.resumeAnimation:this.pauseAnimation}
						>
							{this.state.animationPaused?'Продолжить':'Пауза'}
						</Button>
						{/*}
						<Button
							className={buttonClass}
							disabled={!this.state.ymapsLoaded}
							onClick={()=> {
								zuEventListStorage.dispatch(zuEventListActions.addEvent({
									id: 1,
									time: Date.now(),
									type: 'nmea',
									nmea: 'GPRMC xxxxxxxxxx',
									reason: 'some reason'
								}));
							}}
						>
							Default
						</Button>
						<Button className={buttonClass} disabled={!this.state.ymapsLoaded} color="primary">
							Primary
						</Button>
						<Button className={buttonClass} disabled={!this.state.ymapsLoaded} color="secondary">
							Secondary
						</Button>
						<Button className={buttonClass} disabled={!this.state.ymapsLoaded}>
							Disabled
						</Button>
						<Button className={buttonClass} disabled={!this.state.ymapsLoaded} color="primary" href="#contained-buttons">
							Link
						</Button>*/}
					</Paper>
					<Paper className={buttonsPaperClass}>
						Управление передачей
						<ZuSendTargetDialog
							packetType={this.state.packetType}
							sendType={this.state.sendType}
							sendUrl={this.state.sendUrl}
							disabled={this.state.isAnimating || this.state.isYmapsEditing}
							onSave={(packetType, sendType, sendUrl)=>{
								this.setState({packetType, sendType, sendUrl});
								this.eventSender.packetType=packetType;
								this.eventSender.sendType=sendType;
								this.eventSender.sendUrl=sendUrl;
							}}
							openButtonProps={{
								className: buttonClass,
								variant: "outlined"
							}}
						/>
					</Paper>
				</Grid>
				<Grid item xs={12} md={9} lg={9}>
					<Paper>
						<ZuEventsList />
					</Paper>
				</Grid>
				<Grid item xs={12} md={3} lg={3}>
					<Paper className={buttonsPaperClass}>
						<ZuStateComponent zuState={this.animationZuState}/>
					</Paper>
				</Grid>
				{typeof(this.state.editPointIndex)==='number'?
					<ZuPointEditorDialog
						event={this.state.points[this.state.editPointIndex]}
						onCancel={()=>this.setState({editPointIndex: null})}
						onSave={(newPoint)=>{
							let newPoints=[...this.state.points];  //todo: remove using of state
							newPoints[this.state.editPointIndex]=newPoint;
							this.setState( {points: newPoints, editPointIndex: null} );
						}}
					/>
					:null
				}
			</Grid>
		);
	}
}

export default Zu;