import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import {ZuEventType, ZuPoint, zuEventTypeDisplayName, ZuSignalSource, ZuOnOffReason} from '../lib/ZuPoint';
import MenuItem from "@material-ui/core/MenuItem";
import {Typography} from "@material-ui/core";


export default function ZuSendTargetDialog(props) {
    const [open, setOpen] = React.useState(false);
    const [packetType, setPacketType] = React.useState(props.packetType);
    const [sendType, setSendType] = React.useState(props.sendType);
    const [sendUrl, setSendUrl] = React.useState(props.sendUrl);

    function cancel() {
        if (props.onCancel) props.onCancel();
        setOpen(false);
    }

    const handleClickOpen = () => {
        debugger;
        setPacketType(props.packetType);
        setSendType(props.sendType);
        setSendUrl(props.sendUrl);
        setOpen(true);
    };

    function save() {
        if (props.onSave) props.onSave(packetType, sendType, sendUrl);
        setOpen(false);
    }

    function getOpenButtonText() {
        switch (sendType) {
            case 'direct': return `По умолчанию, ${props.packetType}`;
            case 'url-server': return `На URL с сервера, ${props.packetType}`;
            case 'url-browser': return `На URL из браузера, ${props.packetType}`;
        }
    }

    return (
        <div>
            <Button disabled={props.disabled} { ...props.openButtonProps} color="primary" onClick={handleClickOpen}>
                {getOpenButtonText()}
            </Button>
            <Dialog open={open} onClose={cancel} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Изменение способа отправки</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                    </DialogContentText>
                    <TextField
                        select
                        value={packetType}
                        margin="dense"
                        fullWidth
                        onChange={(e)=>setPacketType(e.target.value)}
                        label="Тип пакета"
                    >
                        <MenuItem value={'plainText'}>Plain text</MenuItem>
                        <MenuItem value={'json'}>JSON</MenuItem>
                    </TextField>
                    <TextField
                        select
                        value={sendType}
                        margin="dense"
                        fullWidth
                        onChange={(e)=>setSendType(e.target.value)}
                        label="Вариант отправки"
                    >
                        <MenuItem value={'direct'}>По умолчанию</MenuItem>
                        <MenuItem value={'url-server'}>На URL с сервера</MenuItem>
                        <MenuItem value={'url-browser'}>На URL из браузера</MenuItem>
                    </TextField>
                    {
                        (sendType!=='direct')?
                            <TextField
                                autoFocus
                                margin="dense"
                                id="name"
                                label="URL"
                                type="email"
                                fullWidth
                                value={sendUrl}
                                onChange={(e) => setSendUrl(e.target.value)}
                            />
                            :null
                    }
                    {
                        (sendType==='url-browser')?
                            <React.Fragment>
                                <Typography align={'justify'}>
                                   Для отправки данных из браузера на произвольный URL необходимо обеспечить корректную настройку CORS для данного URL.
                                   WEB-сервер должен обеспечивать обработку метода OPTIONS и выдавать разрешения на отправку POST-запросов с данного сайта
                                    с данными application/json и text/plain
                                </Typography>
                                <Typography>
                                </Typography>
                                <Typography>
                                    <code>
                                        <strong>Пример правильных HTTP-заголовков</strong>
                                        <p style={{margin: 0}}>Access-Control-Allow-Origin: {document.location.hostname}</p>
                                        <p style={{margin: 0}}>Access-Control-Allow-Methods: POST, GET, OPTIONS</p>
                                        <p style={{margin: 0}}>Access-Control-Allow-Headers: Content-Type</p>
                                    </code>
                                </Typography>
                            </React.Fragment>
                            :null
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={save} color="primary">
                        Сохранить
                    </Button>
                    <Button onClick={cancel} color="secondary">
                        Отменить
                    </Button>
                </DialogActions>
            </Dialog>
        </div>


    );
}