
import Cookies from 'universal-cookie';

import config from '../config';


import {jsonRpcRequest} from "./JsonRpcRequest";



const AUTH_INFO_ITEM_NAME='auth';
const AUTH_VERSION_ITEM_NAME='av';
const AUTH_VERSION_COOKIE_NAME='av';

export function isLoggedIn() {
    return !!getAuthInfo();
}

export function removeAuthInfo() {
    localStorage.removeItem(AUTH_INFO_ITEM_NAME);
    localStorage.removeItem(AUTH_VERSION_ITEM_NAME);
}


export function getAuthInfo() {
    let authInfo, authVersion, authVersionCookie;
    try {
        authInfo = JSON.parse(localStorage.getItem(AUTH_INFO_ITEM_NAME));
        authVersion = localStorage.getItem(AUTH_VERSION_ITEM_NAME);
        authVersionCookie=(new Cookies()).get(AUTH_VERSION_COOKIE_NAME);
    } catch (e) {
        removeAuthInfo();
        return null;
    }
    if (authInfo && authVersion && authVersionCookie && authVersion===authVersionCookie) return authInfo;
    removeAuthInfo();
    return null;
}

export function setAuthInfo(obj, authVersion) {
    localStorage.setItem(AUTH_INFO_ITEM_NAME, JSON.stringify(obj));
    localStorage.setItem(AUTH_VERSION_ITEM_NAME, authVersion);
}

export async function login(username, password, rc2token) {
    try {
        let authInfo = await jsonRpcRequest(config.zu.url.auth, 'login', {
            username,
            password,
            rc2token
        });
        if (authInfo) {
            const authVersion=(new Cookies()).get(AUTH_VERSION_COOKIE_NAME);
            setAuthInfo(authInfo, authVersion);
            console.log('authInfo', authInfo, authVersion);
            return true;
        }
    } catch (e) {}
    return false;
}

export async function logout() {
    removeAuthInfo();
    try {
        await jsonRpcRequest(config.zu.url.auth, 'logout', null);
    } catch (e) {}
    return true;
}

export async function validate() {
    try {
        let authVersion=localStorage.getItem(AUTH_VERSION_ITEM_NAME);
        if (!authVersion) return false;
        let valid=await jsonRpcRequest(config.zu.url.auth, 'validate', {
            av: authVersion
        });
        if (!valid) removeAuthInfo();
        return valid;
    } catch (e) {}
    return false;
}
