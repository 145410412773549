import React from 'react';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';

import { YMaps, Map } from 'react-yandex-maps';
import AlertDialog from "./AlertDialog";
import {ZuPoint} from '../lib/ZuPoint';
import DcEventsList from "./DcEventsList";

import config from '../config';
import DcZuMaskEditorDialog from "./DcZuMaskEditorDialog";
import {DcWsConnection} from "../lib/DcWsConnection";
import {decodeNmeaRmc} from "../lib/nmea";
import DcPolylines from "./DcPolylines";
import Typography from "@material-ui/core/Typography";


class Dc extends React.Component {
	constructor(props) {
		super(props);
		this.state={
			zuMask: '.+',


			points: {},


			currentPosition: null,

			ymapsLoaded: false,
			alertText: null,
			alertTitle: null,

			wsConnected: false,

		};
		this.onYMapsLoad=this.onYMapsLoad.bind(this);
		this.clearPoints=this.clearPoints.bind(this);
		this.sendDeviceMaskToWs=this.sendDeviceMaskToWs.bind(this);
		this.addEventFromWs=this.addEventFromWs.bind(this);
		//refs
		this.ymaps=null;
		this.ymap=null;
		//this.panToDeviceId=null;

		this.ws=new DcWsConnection({ url: config.dc.url.ws });
		this.ws.onConnectedChange=(connected) => {
			this.setState({wsConnected: connected});
			if (connected) this.sendDeviceMaskToWs();
		};
		this.ws.onMessage=this.addEventFromWs;
		this.ws.connect();

		navigator.geolocation.getCurrentPosition((position)=> {
			this.setCurrentPosition([position.coords.latitude, position.coords.longitude])
		});
	}

	addEventFromWs(packet) {
		try {
			console.log('packet from ws', packet);
			let doPenTo=false;
			let {battery, period, events}=packet;
			events=Array.from(events.values());
			let deviceId=packet.deviceId; //['device-id'];
			let newPoints={};
			for (let key of Object.keys(this.state.points)) newPoints[key]=[...this.state.points[key]];
			if (!(deviceId in newPoints)) {
				newPoints[deviceId] = [];
				doPenTo=true;
			}

			let lastCoordEvent=null;
			events.forEach( (event)=>{
				event.deviceId=deviceId;
				event.battery=battery;
				event.period=period;
				if (event.nmea) Object.assign(event, decodeNmeaRmc(event.nmea));
				if (event.type==='coord') lastCoordEvent=event;
				if (typeof(event.id)===undefined) event.id=null;
				if (typeof(event.time)===undefined) event.time='';
			});
			let zuEvents=events.map( (event)=> {
				return new ZuPoint(event);
			});
			newPoints[deviceId]=[...newPoints[deviceId], ...zuEvents];
			this.setState({points: newPoints})
			if (lastCoordEvent && doPenTo) {
				console.log("pento", lastCoordEvent.latitude, lastCoordEvent.longitude)
				this.ymap.panTo([lastCoordEvent.latitude, lastCoordEvent.longitude]);
			}
		} catch (e) {
			console.log('error while processing event from ws', e);
		}
	}

	sendDeviceMaskToWs(value) {
		this.ws.send(JSON.stringify({
			'action': 'setDeviceIdMask',
			'mask': value || this.state.zuMask
		}));
	}

	setStateFieldValue(fieldName, e) {
		this.setState({fieldName: e.target.value});
	}

	setStateIntFieldValue(fieldName, e) {
		this.setState({fieldName: parseInt(e.target.value)});
	}

	setStateFieldChecked(fieldName, e) {
		this.setState({fieldName: e.target.checked});
	}

	onYMapsLoad(ymaps) {
		window.ymaps=this.ymaps=ymaps;

		this.setState({ymapsLoaded: true});
		if (this.state.currentPosition) {
			this.ymap.setCenter(this.state.currentPosition, config.dc.ui.ymaps.default_zoom, {duration: config.dc.ui.ymaps.center_duration});
		}
	}

	setCurrentPosition(point) {
		this.setState({currentPosition: point});
		if (this.state.ymapsLoaded) {
			this.ymap.setCenter(point, config.dc.ui.ymaps.default_zoom, {duration: config.dc.ui.ymaps.center_duration});
		}
	}

	clearPoints() {
		this.setState({points: {}});
	}




	render () {

		//console.log("state", this.state)
		const classes = this.props.classes;

		const buttonsPaperClass=clsx(classes.paper, {
			display: 'flex',
			flexDirection: 'column',
		});
		const buttonClass=clsx({
		});
		//const fixedHeightPaper= clsx(classes.paper, classes.fixedHeight);

		return (
			<Grid container spacing={3}>
				<Grid item xs={12} md={9} lg={9}>
					<Paper>
						<YMaps query={{ lang: 'ru_RU', apikey: 'd9671ec9-8b1f-4000-a1c1-6ba96f87656e' }}>
							<Map
								modules={config.dc.ui.ymaps.modules}
								onLoad={this.onYMapsLoad}
								instanceRef={inst => { window.ymap=this.ymap=inst } }
								width="100%"
								height="600px"
								defaultState={{
									center: config.dc.ui.ymaps.default_center,
									zoom: config.dc.ui.ymaps.default_zoom,
									controls: config.dc.ui.ymaps.controls
								}}
							>
								<DcPolylines points={this.state.points} />
							</Map>
						</YMaps>
					</Paper>
				</Grid>
				<Grid item xs={12} md={3} lg={3}>
					<Paper className={buttonsPaperClass}>
						<Typography
							align="center"
							color={this.state.wsConnected?'primary':'secondary'}
						>
							{this.state.wsConnected?'Подключено':'Нет соединения'}
						</Typography>
					</Paper>

					<Paper className={buttonsPaperClass}>
						<DcZuMaskEditorDialog
							zuMask={this.state.zuMask}
							openButtonProps={{
								className: buttonClass,
								disabled: !this.state.ymapsLoaded,
								text: `Маска: /${this.state.zuMask}/`,
								variant: "outlined"
							}}
							onSave={(newZuMask)=>{
								this.setState({zuMask: newZuMask});
								if (this.ws.connected) this.sendDeviceMaskToWs(newZuMask);
							}}
						/>
					</Paper>

					<Paper className={buttonsPaperClass}>
						{/* variant="contained"  */}
						<Button
							className={buttonClass}
							onClick={this.editMapClickHandler}
							disabled={true}
							color="primary"
							variant="contained"
						>
							Загрузить из архива
						</Button>
						<Button
							className={buttonClass}
							disabled={!Object.keys(this.state.points).length}
							onClick={this.clearPoints}
						>
							Очистить
						</Button>
						<Button
							className={buttonClass}
							disabled={!this.state.points.length || true}
							onClick={this.savePoints}
						>
							Сохранить
						</Button>
						<input ref={ inst=> { this.uploadInput=inst; } } style={{display: 'none'}} type={'file'} onChange={this.loadPoints} />
						<Button
							className={buttonClass}
							disabled={!this.state.ymapsLoaded || true}
							onClick={ ()=>{
								this.uploadInput.value='';
								this.uploadInput.click()
							} }
						>
							Загрузить
						</Button>
						<AlertDialog alertText={this.state.alertText} alertTitle={this.state.alertTitle} onClose={()=>this.setState({alertText: null})} />
					</Paper>
				</Grid>
				<Grid item xs={12} md={9} lg={9}>
					<Paper>
						<DcEventsList events={this.state.points} />
					</Paper>
				</Grid>
				{/*<Grid item xs={12} md={3} lg={3}>
					<Paper className={buttonsPaperClass}>


						<ZuStateComponent zuState={new ZuState()}/>
					</Paper>
				</Grid>*/}
			</Grid>
		);
	}
}

export default Dc;