import {ZuEventType} from "./ZuPoint";
import {getChecksum, getPermutation, mask} from "./mask";


let nextEventId=1;
let nextPacketId=1;

export class ZuPacket {
    constructor({deviceId, battery, period}) {
        this.deviceId=deviceId;
        this.battery=battery;
        this.period=period;
        this.events=new Map();
        this.id=nextPacketId++;
        this.s=Math.floor(Math.random()*1000000);
        this.useMask=true;
    }

    addEvent(event) {
        if (this.events.has(event.id)) return;
        if (this.events.size===0) {
            this.deviceId=this.deviceId || event.deviceId;
            this.battery=typeof(event.battery)!=='undefined'?event.battery:this.battery;
            this.period=event.period || this.period;
        }
        let eventInfo;
        switch (event.type) {
            case ZuEventType.coord:
                eventInfo={type: event.type, nmea: event.nmea};
                break;
            case ZuEventType.lowBattery:
            case ZuEventType.removed:
                eventInfo={type: event.type, time: event.time};
                break;
            case ZuEventType.on:
            case ZuEventType.off:
                eventInfo={type: event.type, time: event.time, reason: event.reason};
                break;
            case ZuEventType.signal:
            case ZuEventType.noSignal:
                eventInfo={type: event.type, time: event.time, source: event.source};
                break;
            default:
                console.log('ZuJsonPacket.addEvent: unknown event type');
                break;
        }
        if (eventInfo) {
            this.events.set(event.id, eventInfo);
        }
    }

    asPlainText() {
        let lines=[];
        lines.push(`ver=1`);
        lines.push(`devId=${this.deviceId}`);
        lines.push(`bat=${this.battery}`);
        lines.push(`temp=0`);
        lines.push(`per=${this.period}`);
        this.events.forEach( (event) => {
            switch (event.type) {
                case ZuEventType.coord:
                    lines.push(`nmea=${event.nmea}`);
                    break;
                case ZuEventType.lowBattery:
                case ZuEventType.removed:
                    lines.push(`${event.type}=${event.time}`);
                    break;
                case ZuEventType.on:
                case ZuEventType.off:
                    lines.push(`${event.type}=${event.time},${event.reason}`);
                    break;
                case ZuEventType.signal:
                case ZuEventType.noSignal:
                    lines.push(`${event.type}=${event.time},${event.source}`);
                    break;
                default:
                    console.log('ZuPlainPacket.toString: unknown event type');
                    break;
            }
        });
        let res=lines.join('\n');
        if (this.useMask) {
            let perm=getPermutation(3457, this.s);
            res=mask(res, perm);
        }
        return res;
    }

    asObject() {
        return {
            'device-id': this.deviceId,
            battery: this.battery,
            period: this.period,
            events: Array.from(this.events.values())
        }
    }

    fromObject(obj) {
        this.deviceId=obj['device-id'];
        this.battery=obj.battery;
        this.period=obj.period;
        this.events=new Map();
        obj.events.forEach((event)=>this.events.set(nextEventId++, event));
    }

    fromPlainText(str) {
        str=str.replace(/\r\n/g, '\n').replace(/\n\n/g, '\n');
        let lines=str.split('\n');
        lines.forEach( (line)=>{
            let [key, value]=line.split('=');
            switch (key) {
                case 'ver':
                    break;
                case 'devId':
                    this.deviceId=value;
                    break;
                case 'bat':
                    this.battery=parseInt(value);
                    break;
                case 'temp':
                    this.temperature=parseInt(value);
                    break;
                case 'per':
                    this.period=parseInt(value);
                    break;
                case 'nmea':
                    if (value[0]!=='$') value='$'+value;
                    this.events.set(nextEventId++, {type: ZuEventType.coord, nmea: value});
                    break;
                case ZuEventType.lowBattery:
                case ZuEventType.removed:
                    this.events.set(nextEventId++, {type: key, time: value});
                    break;
                case ZuEventType.on:
                case ZuEventType.off:
                    let [time, reason]=value.split(',');
                    this.events.set(nextEventId++, {type: key, time: time, reason: reason});
                    break;
                case ZuEventType.signal:
                case ZuEventType.noSignal:
                    let [time2, source]=value.split(',');
                    this.events.set(nextEventId++, {type: key, time: time2, source: source});
                    break;
            }
        });
    }

    eventIds() {
        return Array.from(this.events.keys());
    }

    checkSum() {
        let plainText=this.asPlainText();
        return getChecksum(plainText);
    }

    queryString() {
        return `?id=${this.id}&dev-id=${this.deviceId}&s=${this.s}&check=${this.checkSum()}&bat=${this.battery}`;
    }
}
