import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


export default function DcZuMaskEditorDialog(props) {
    const [open, setOpen] = React.useState(false);
    const [zuMask, setZuMask] = React.useState(props.zuMask);

    function cancel() {
        if (props.onCancel) props.onCancel();
        setOpen(false);
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    function save() {
        if (props.onSave) props.onSave(zuMask);
        setOpen(false);
    }

    return (
        <div>
            <Button {...props.openButtonProps} color="primary" onClick={handleClickOpen}>
                {props.openButtonProps.text}
            </Button>
            <Dialog open={open} onClose={cancel} aria-labelledby="form-dialog-title">
                <DialogTitle>Изменение маски приема</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        fullWidth
                        value={zuMask}
                        onChange={(e)=>setZuMask(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={save} color="primary">
                        Сохранить
                    </Button>
                    <Button onClick={cancel} color="secondary">
                        Отменить
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}