import React from 'react';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';

import { YMaps, Map } from 'react-yandex-maps';
import AlertDialog from "./AlertDialog";
import {ZuPoint} from '../lib/ZuPoint';
import DcEventsList from "./DcEventsList";

import config from '../config';
import DcZuMaskEditorDialog from "./DcZuMaskEditorDialog";
import {DcWsConnection} from "../lib/DcWsConnection";
import {decodeNmeaRmc} from "../lib/nmea";
import DcPolylines from "./DcPolylines";
import Typography from "@material-ui/core/Typography";


class About extends React.Component {
    constructor(props) {
        super(props);
    }

    render () {
        const classes = this.props.classes;
        //return null;
        return (
            <Grid container spacing={3}>
                <Grid item xs={12} md={9} lg={7}>
                    <Paper style={{padding: 50}}>
                        <Typography align={'justify'} >
                            <h3>Порядок работы с имитатором ЗУ:</h3>
                            <li>Создать (кнопка "Редактировать точки") или загрузить ранее созданные (кнопка "Загрузить") точки маршрута.</li>
                            <li>Проставить имитируемые события. Для настройки точки щелкните по ней мышкой</li>
                            <li>Задать параметры движения (скорость, интервалы отсчетов и отправки) в разделе "Управление движением"</li>
                            <li>Настройте параметры отправки пакетов данных в разделе "Управление передачей". доступны для выбора форматы пакетов (plain/json)
                                и способ отправки. При отправке "по умолчанию" используется внутренний API, возможна отправка на произвольный URL.
                            </li>
                            <li>При необходимости задать идентификатор устройства.
                                При открытии имитатора задается случайный идентификатор устройства.</li>
                            <li>Для начала движения нажать кнопку "Начать движение". После нажатия начнется анимация движения,
                                в таблице внизу страницы будут отображаться сформированные события и состояние по их отправке</li>
                        </Typography>
                        <Typography align={'justify'} >
                            <h3>Порядок работы с имитатором ДЦ:</h3>
                            <p>Подключение к серверу и прием сообщений начинается автоматически аосле открытия страницы имитатора.
                                При работе с несколькми ЗУ может быть настроена маска приема. Маска задается регулярным выражением.
                                В имитаторе ДЦ будут отображаться только те устройства, идентификаторы которых соответствуют маске.
                            </p>
                        </Typography>
                        <Typography align={'justify'} >
                            <h4>Примечание</h4>
                            Допускается одновременное использование нескольких имитаторов ЗУ/ДЦ. Для этого откройте нужное количество вкладок/браузеров.
                            Во избежание "смешивания" треков каждый имитатор ЗУ должен использовать уникальый идентификатор устройства.
                        </Typography>
                    </Paper>
                </Grid>
            </Grid>
        );
    }
}

export default About;