
export const ZuEventType={
    on: 'on',
    off: 'off',
    signal: 'signal',
    noSignal: 'no-signal',
    coord: 'coord',
    removed: 'removed',
    lowBattery: 'low-battery'
};

export function zuEventTypeDisplayName(type) {
    switch (type) {
        case ZuEventType.coord:
            return 'Координата';
        case ZuEventType.on:
            return 'Включение';
        case ZuEventType.off:
            return 'Выключение';
        case ZuEventType.signal:
            return 'Восстановление сигнала';
        case ZuEventType.noSignal:
            return 'Потеря сигнала';
        case ZuEventType.removed:
            return 'Удалено';
        case ZuEventType.lowBattery:
            return 'Разряд батареи';
        default:
            return '';
    }
}

export const ZuSignalSource={
    gsm: 'gsm',
    sat: 'satellite'
};

export const ZuOnOffReason={
    power: 'power',
    accelerometer: 'accelerometer'
};

export function ZuPoint({type, coord, source, reason, time, nmea, battery, period, deviceId, latitude, longitude, info}) {
    this.type=type || ZuEventType.coord;
    this.coord=coord?coord:[latitude, longitude];
    this.latitude=this.coord[0];
    this.longitude=this.coord[1];
    this.source=source || '';
    this.reason=reason || '';
    this.time=time;
    this.nmea=nmea || '';

    this.battery=battery;
    this.period=period;
    this.deviceId=deviceId;

    this.info = info
}

export function ZuEvent({type, coord, source, reason, time, nmea}) {
    this.type=type || ZuEventType.coord;
    this.coord=coord;
    this.latitude=coord[0];
    this.longitude=coord[1];
    this.source=source || '';
    this.reason=reason || '';
    this.time=time;
    this.nmea=nmea || '';
}

