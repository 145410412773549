import * as nmea from '../lib/nmea';
import {ZuEventType} from "../lib/ZuPoint";


export function markAllEventsSent() {
    return {
        type: "events.markAllSent"
    }
}


export function stopNotSent() {
    return {
        type: "events.stopNotSent"
    }
}

export function setSent(eventId, value) {
    return {
        type: "events.setSent",
        eventId,
        value
    };
}

export function addCoordEvent([latitude, longitude]) {
    return addEvent({
        time: (new Date()).toISOString(),
        type: 'coord',
        latitude,
        longitude,
        nmea: nmea.encodeRmc([latitude, longitude], 60)
    })
}

let nextEventId=1;

export function addEvent(event) {
    return {
        type: "events.add",
        event: {
            ...event,
            id: nextEventId++
        }
    }
}


export function addZuEvent(event) {
    event.time=(new Date()).toISOString();
    /*
    if (event.type===ZuEventType.coord) {
        const rnd = Math.random()
        if (rnd<0.3) event.nmea = `$PUBX,00,081350.00,4717.113210,N,00833.915187,E,546.589,G3,2.1,2.0,0.007,77.52,0.007,,0.92,1.19,0.77,9,0,0*5F`
        else if (rnd<0.7) event.nmea = `$PUBX,00,080959.00,5544.25608,N,03758.13726,E,140.335,G3,14,22,0.424,242.65,-0.082,,1.54,2.63,1.84,6,0,0*47`
        else event.nmea = `$PUBX,00,081056.00,5544.25399,N,03758.13589,E,153.975,G3,12,15,0.388,242.65,-0.033,,1.45,2.28,1.56,7,0,0*4E`
    }*/
    if (event.type===ZuEventType.coord) event.nmea=nmea.encodeRmc(event.coord, 60);

    return addEvent(event);
}

export function clearEvents() {
    return {
        type: "events.clear"
    }
}