async function httpPost(url, data, contentType) {
    return new Promise( (resolve, reject) => {
        let xhr = new window.XMLHttpRequest();
        xhr.onreadystatechange = ()=>{
            if (xhr.readyState !== 4) return;
            if (xhr.status !== 200) {
                reject(`http error status=${xhr.status}`);
            } else {
                resolve(xhr.responseText);
            }
            xhr=undefined;
        };
        xhr.error=()=>{
            xhr=undefined;
            reject('http error');
        };
        xhr.open('post', url, true);
        xhr.setRequestHeader("Content-Type", contentType); //"text/plain;charset=UTF-8");
        xhr.send(data);
    });
}

let nextRequestId=1;

export async function jsonRpcRequest(url, method, params) {
    let request={
        jsonrpc: '2.0',
        method: method,
        params: params,
        id: nextRequestId++
    };
    let responseText=await httpPost(url, JSON.stringify(request), 'application/json;charset=UTF-8');
    try {
        let obj=JSON.parse(responseText);
        if (obj.error) {
            console.log(`server error: ${obj.error.code} ${obj.error.message}`);
            return undefined;
        } else {
            if (typeof(obj.result)!=='undefined') return obj.result;
            else return undefined;
        }
    } catch (e) {
        console.log('cannot parse server response');
        return undefined;
    }
}

