import React from "react";
import {Polyline} from "react-yandex-maps";
import {ZuState} from "../lib/ZuState";



export default function ZuAnimationLines(props) {
    if (!props.isAnimating) { // noinspection JSConstructorReturnsPrimitive
        return null;
    }

    let zuState=props.zuState;
    if (!zuState) zuState=new ZuState();

    function getColor() {
        return zuState.getLineColor();

        if (!zuState.isPowerOn) return "#000000";
        if (zuState.isRemoved) return "#FF0000";
        if (zuState.noSignalGsm) return '#808080';
        //if (noSignalSat) return '#4B0082';
        return "#0011aaaa";

    }

    function getStyle() {
        //if (noSignalSat) return '2 2';
        if (zuState.noSignalSat) return '2 2';
        return '';
    }

    return props.points.reduce( (lines, point, index) => {
        //debugger;
        if (index!==0) {
            lines.push(
                <Polyline key={index - 1}
                          geometry={[...props.animationLines[index - 1]]}
                          options={{
                              strokeColor: getColor(),
                              strokeWidth: 3,
                              strokeStyle: getStyle()
                          }}
                />
            );
        }
        zuState.updateState(point);
        return lines;
    }, []);
};