import * as nmea from '@drivetech/node-nmea';

const pubx = /\$?(PUBX),(00),(\d{6}\.\d{2})?,(\d+\.\d+,[NS])?,(\d+\.\d+,[EW])?,(\d+(?:\.\d+)?)?,(NF|DR|G2|G3|D2|D3|RK|TT)?,(\d+(?:\.\d+)?)?,(\d+(?:\.\d+)?)?,(\d+(?:\.\d+)?)?,(\d+(?:\.\d+)?)?,([+\-]?\d+(?:\.\d+)?)?,(\d+(?:\.\d+)?)?,(\d+(?:\.\d+)?)?,(\d+(?:\.\d+)?)?,(\d+(?:\.\d+))?,(\d+(?:\.\d+)?)?,(\d+(?:\.\d+)?)?,(\d+(?:\.\d+)?)?\*([0-9a-zA-Z]{2})/i
    ///\$?(PUBX,00),(\d{6}\.\d{2})?,(\d+\.\d+,[NS])?,(\d+\.\d+,[EW])?,(\d+\.\d+)?,(NF|DR|G2|G3|D2|D3|RK|TT)?,(\d+\.\d+)?,(\d+\.\d+)?,(\d+\.\d+)?,(\d+\.\d+)?,(\d+\.\d+)?,([+\-]?\d+\.\d+)?,(\d+\.\d+)?,(\d+\.\d+)?,(\d+\.\d+)?,(\d+)?,(\d+)?,(\d+)?\*([0-9a-zA-Z]{2})/i


const hhmmssToDate = (hhmmss) => {
    const date = new Date();
    const now_utc = Date.UTC(date.getUTCFullYear(), date.getUTCMonth(),
        date.getUTCDate(), date.getUTCHours(),
        date.getUTCMinutes(), date.getUTCSeconds());
    const utcDateAndT = new Date(now_utc).toISOString().substring(0, 11)
    const dateStr = utcDateAndT + hhmmss.substring(0, 2) + ":" + hhmmss.substring(2, 4) + ":" + hhmmss.substring(4) + "Z"
    return new Date(dateStr)
}



const dmmToDec = data => {
    let decimal = 0.0
    const _data = data.match(/(\d{2,3})(\d{2}[.]\d+),([NSWE])/).slice(1)
    const deg = _data[0]
    const min = _data[1]
    const sign = _data[2]
    decimal = parseFloat(deg) + parseFloat(min) / 60
    if (sign === 'S' || sign === 'W') {
        decimal *= -1
    }
    return decimal
}

const tryParseFloat = str => {
    return str ? parseFloat(str) : undefined
}

function parsePubx(text) {
    const match= pubx.exec(text)
    if (!match) return {}

    return {
        pubx: match[1],
        messageId: match[2],
        time: hhmmssToDate(match[3]),
        latitude: dmmToDec(match[4]),
        longitude: dmmToDec(match[5]),
        altRef: tryParseFloat(match[6]),
        navStat: match[7],
        hAcc: tryParseFloat(match[8]),
        vAcc: tryParseFloat(match[9]),
        sog: tryParseFloat(match[10]),
        cog: tryParseFloat(match[11]),
        vVel: tryParseFloat(match[12]),
        ageC: tryParseFloat(match[13]),
        hdop: tryParseFloat(match[14]),
        vdop: tryParseFloat(match[15]),
        tdop: tryParseFloat(match[16]),
        gu: tryParseFloat(match[17]),
        ru: tryParseFloat(match[18]),
        dr: tryParseFloat(match[19]),
        checksum: match[20]
    }

    // 1 - PUBX,00
    // 2 - hhmmss.ss
    // 3 - Latitude,N
    // 4 - Longitude,E
    // 5 - AltRef
    // 6 - NavStat  NF|DR|G2|G3|D2|D3|RK|TT
    // 7 - Hacc (\d+\.\d+)
    // 8 - Vacc (\d+\.\d+)
    // 9 - SOG - (\d+\.\d+)
    // 10 - COG - (\d+\.\d+)
    // 11 - Vvel - (\d+\.\d+)
    // 12 - AgeC - ([+\-]?\d+\.\d+)
    // 13 - HDOP - (\d+\.\d+)
    // 14 - VDOP - (\d+\.\d+)
    // 15 - TDOP - (\d+\.\d+)
    // 16 - GU - (\d+)
    // 17 - RU - (\d+)
    // 18 - DR - (\d+)
    // 19 - hh - ([0-9a-zA-Z]{2})
}


export function decodeNmeaRmc(rmc) {
    if (/\$?g.rmc/i.test(rmc)) {
        let obj = nmea.parse(rmc);
        return {
            time: obj.datetime.toISOString(),
            coord: [obj.loc.geojson.coordinates[1], obj.loc.geojson.coordinates[0]],
            latitude: obj.loc.geojson.coordinates[1],
            longitude: obj.loc.geojson.coordinates[0],
        }
    } else if (/\$?PUBX,00/) {
        let obj = parsePubx(rmc)
        return {
            time: obj.time.toISOString(),
            coord: [obj.latitude, obj.longitude],
            latitude: obj.latitude,
            longitude: obj.longitude,
            info: `navStat=${obj.navStat} dr=${obj.dr}`
        }
    } else return {}
}


export function encodeRmc([latitude, longitude], speedKmH) {
    let utc=(new Date()).toISOString();

    //console.log(utc);
    let str='$GPRMC,'+utc.replace(/\d+-\d+-\d+T|[:Z]/g, '')+',A,'+
        //'3321.6735,S'+','+'07030.7640,W,'+
        nmea.latToDmm(latitude)+','+nmea.lngToDmm(longitude)+','+
        Math.floor(speedKmH*100/1.85)/100+','+
        '0.00,'+
        utc.substring(8,10)+utc.substring(5,7)+utc.substring(2,4)+',,,A*';

    //console.log(nmea.getChecksum(str));
    let code=0;
    for (let t=1;t<str.length-1;t++) {
        code=code ^ str.charCodeAt(t);
    }
    str=str+code.toString(16).toUpperCase();
    if (str[0]==='$') str=str.substring(1);
    return str;
}
