
import React, {useState} from 'react';

import {connect, Provider} from 'react-redux';
import zuEventListStore from '../stores/zuEventListStore';
import {IntegratedPaging, PagingState} from '@devexpress/dx-react-grid';
import {Grid, Table, TableHeaderRow, PagingPanel, TableColumnVisibility, TableColumnResizing, ColumnChooser, Toolbar } from '@devexpress/dx-react-grid-material-ui';

let columns=[
    { name: 'id', title: 'id' },
    { name: 'time', title: 'Время' },
    { name: 'type', title: 'Событие' },
    { name: 'latitude', title: 'Широта' },
    { name: 'longitude', title: 'Долгота' },
    { name: 'nmea', title: 'NMEA' },
    { name: 'source', title: 'Cигнал'},
    { name: 'reason', title: 'Причина' },
    { name: 'sent', title: 'Отправка' }
];

let columnWidths=[
    { columnName: 'id', width: 70 },
    { columnName: 'time', width: 200 },
    { columnName: 'type', width: 100 },
    { columnName: 'latitude', width: 100 },
    { columnName: 'longitude', width: 100 },
    { columnName: 'nmea', width: 550 },
    { columnName: 'source', width: 100},
    { columnName: 'reason', width: 150 },
    { columnName: 'sent', width: 200 }
];

let hiddenColumns=['id', 'time', 'latitude', 'longitude'];

function ZuEventsList({rows}) {
   const [defaultColumnWidths] = useState(columnWidths);
    const [defaultHiddenColumnNames] = useState(hiddenColumns);
    return (
        <Grid rows={rows} columns={columns}>
            <PagingState
                defaultCurrentPage={0}
            />
            <IntegratedPaging />
            <Table />
            <TableColumnResizing defaultColumnWidths={defaultColumnWidths} />
            <TableHeaderRow />
            <TableColumnVisibility
                defaultHiddenColumnNames={defaultHiddenColumnNames}
            />
            <Toolbar />
            <ColumnChooser />
            <PagingPanel pageSizes={[10, 20, 30, 0]} />
        </Grid>
    )
}

/*
function ZuReduxEventsList() {
    return (
    );
}
*/

function mapStateToProps(state) {
    return {
        rows: state //.map( (eventInfo)=>eventInfo.event)
    };
}

let ZuReduxEventsList=connect(mapStateToProps, {})(ZuEventsList);


export default () => {
    return (
        <Provider store={zuEventListStore}>
            <ZuReduxEventsList />
        </Provider>
    );
}
