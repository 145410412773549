import React from "react";
import {Placemark} from "react-yandex-maps";
import {ZuState} from "../lib/ZuState";


export default function ZuPlacemarks({points, onPlacemarkClick, zuState}) {
    if (!zuState) zuState=new ZuState();
    return points.reduce((acc, event, index) => {
        zuState.updateState(event);
        acc.push(
            <Placemark key={index}
                       geometry={event.coord}
                       options={{preset: zuState.getEventIconType(event), iconColor: zuState.getEventColor(event)}}
                       properties={{hintContent: zuState.getEventHint(event, 'Исходящее состояние')}}
                       onClick={() => { if (onPlacemarkClick) onPlacemarkClick(index); } }
            />
        );
        return acc;
    }, []);
};
