import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import Zu from './Zu';
import Dc from './Dc';

import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import NotificationsIcon from '@material-ui/icons/Notifications';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import LoginDialog from "./LoginDialog";
import {jsonRpcRequest} from "../lib/JsonRpcRequest";

import * as auth from '../lib/auth';
import About from "./About";

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
  	display: 'block',
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
	  //marginLeft: '0px',
	  //widht: '100%'
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));



function ListItemHref(props) {
	let history = useHistory();

	function handleClick() {
		history.push(props.href);
	}

	let icon=props.icon || null;
	let disabled=props.disabled;
	return (
	<ListItem button onClick={handleClick} disabled={disabled}>
		<ListItemIcon>
			{icon}
		</ListItemIcon>
		<ListItemText primary={props.text} />
	</ListItem>
	);
}

let isAuthValidated=false;
let isAuthValidating=false;

export default function Main(props) {
	const classes = useStyles();

	const [isLoggedIn, setIsLoggedIn] = React.useState(auth.isLoggedIn());
	const [open, setOpen] = React.useState(true);
    const [cnt, setCnt] = React.useState(0);
	const handleDrawerOpen = () => {
		setOpen(true);
	};
	const handleDrawerClose = () => {
		setOpen(false);
	};


	async function login(username, password, rc2token) {
	    let success=await auth.login(username, password, rc2token);
        setIsLoggedIn(success);
        return success;
	}

	async function logout() {
	    await auth.logout();
		setIsLoggedIn(false);
	}

	if (!isAuthValidated) {
	    if (!isAuthValidating) {
            auth.validate().then((valid) => {
                isAuthValidating=false;
                isAuthValidated=true;
                setIsLoggedIn(valid);
                setCnt(cnt+1);
            });
            isAuthValidating=true;
        }
        return <div />;
    }

	return (
		<div className={classes.root}>
			{isLoggedIn?
		<Router>
			<CssBaseline />
			<AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
				<Toolbar className={classes.toolbar}>
					<IconButton
						edge="start"
						color="inherit"
						aria-label="open drawer"
						onClick={handleDrawerOpen}
						className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
					>
						<MenuIcon />
					</IconButton>

					<Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
						<Switch>
							<Route exact path='/' >Аналог</Route>
							<Route exact path='/zu'>Имитатор ЗУ</Route>
							<Route exact path='/dc'>Имитатор ДЦ</Route>
						</Switch>
					</Typography>
					<IconButton color="inherit">
						<Badge badgeContent={0} color="secondary">
							<NotificationsIcon />
						</Badge>
					</IconButton>
				</Toolbar>
			</AppBar>
			<Drawer
				variant="permanent"
				classes={{
				paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
				}}
				open={open}
			>
				<div className={classes.toolbarIcon}>
					<IconButton onClick={handleDrawerClose}>
						<ChevronLeftIcon />
					</IconButton>
				</div>
				<Divider />
				<List>
					<ListItemHref text="Начальная" href="/" icon={(<DashboardIcon />)}/>
					<ListItemHref text="Имитатор ЗУ" href="/zu" icon={(<ShoppingCartIcon />)}/>
					<ListItemHref text="Имитатор ДЦ" href="/dc" icon={(<ShoppingCartIcon />)}/>
				</List>
				<Divider />
				<List>
					<ListItemHref disabled text="Настройки" href="/settings" icon={(<ShoppingCartIcon />)}/>
				</List>
                <Divider />
                <List>
                    <ListItem button onClick={()=>window.open(`/`, '_blank')} disabled={!isLoggedIn}>
                        <ListItemIcon>
                            <ShoppingCartIcon />
                        </ListItemIcon>
                        <ListItemText primary={'Новое окно'} />
                    </ListItem>
                </List>
				<Divider />
				<List>
					<ListItem button onClick={()=>logout()} disabled={!isLoggedIn}>
						<ListItemIcon>
							<ShoppingCartIcon />
						</ListItemIcon>
						<ListItemText primary={'Выход'} />
					</ListItem>
				</List>
			</Drawer>
			<main className={classes.content}>
				<div className={classes.appBarSpacer} />
				<Container maxWidth={false} className={classes.container}>
					<Switch>
						<Route exact path='/' ><About /></Route>
						<Route exact path='/zu'><Zu classes={classes} /></Route>
						<Route exact path='/dc'><Dc classes={classes} /></Route>
					</Switch>
					
					<Box pt={4}>
					</Box>
				</Container>
			</main>
		</Router>
				:<LoginDialog onLogin={login} />}

				</div>
	);
}
