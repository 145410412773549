import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ReCAPTCHA from "react-google-recaptcha";
import {jsonRpcRequest} from "../lib/JsonRpcRequest";
import {Typography} from "@material-ui/core";


export default function LoginDialog(props) {
    const [username, setUsername] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [loginEnabled, setLoginEnabled] = React.useState(false);
    const [inProgress, setInProgress] = React.useState(false);
    const [rc2filled, setRc2Filled] = React.useState(false);
    const [warningText, setWarningText] = React.useState('');

    const rc2 = React.useRef(null);

    async function login() {
        if (props.onLogin) {
            let rc2token=rc2.current.getValue();//document.querySelector('#g-recaptcha-response');
            //rc2token=rc2token.value || rc2token.textContent;

            setInProgress(true);
            let success;
            try {
                debugger
                success=await props.onLogin(username, password, rc2token);
            } catch (e) {}
            setInProgress(false);
            if (!success) {
                setWarningText('Не удалось войти');
                rc2.current.reset();
                setRc2Filled(false);
                setTimeout(()=>{
                    setWarningText('');
                }, 3000);
            }
        }
    }


    return (
        <div>
            <Dialog open={true} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title" style={{textAlign: "center"}}>Вход</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                    </DialogContentText>
                    {
                        warningText?
                            <Typography style={{color: 'red', margin: 'auto'}}>
                                {warningText}
                            </Typography>
                            :null
                    }
                    <TextField
                        autoFocus
                        margin="dense"
                        fullWidth
                        value={username}
                        label={'Имя пользователя'}
                        onChange={(e)=>setUsername(e.target.value)}
                    />
                    <TextField
                        margin="dense"
                        fullWidth
                        value={password}
                        label={'Пароль'}
                        type={'password'}
                        onChange={(e)=>setPassword(e.target.value)}
                    />
                    <br />
                    <br />
                    <div style={{marginLeft: 50}}>
                        <ReCAPTCHA
                            sitekey="6LeQag4mAAAAACnBHg9vCBXu7eBps_JXp2rZUAhR"
                            onChange={()=>setRc2Filled(true)}
                            ref={rc2}
                        />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={login} color="primary" disabled={!username || !password || !rc2filled || inProgress} variant="contained" style={{width: 200, margin: 'auto'}}>
                        Войти
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}


/*
                    <div style={{marginLeft: 50}}>
                        <ReCAPTCHA
                            sitekey="6LegkvoUAAAAAKjTTm007yAdcxyCndayne2anT4F"
                            onChange={()=>setRc2Filled(true)}
                            ref={rc2}
                        />
                    </div>
*/