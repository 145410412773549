import zuEventListStore from '../stores/zuEventListStore';
import * as zuEventListActions from "../actions/zuEventListActions";
import {ZuPacket} from "./ZuPacket";
import config from "../config";




export class ZuEventSender {
    constructor({dataUrl, deviceId, battery, period}) {
        this.dataUrl=dataUrl;
        this.deviceId=deviceId;
        this.battery=battery;
        this.period=period;

        this.packetType='json';
        this.sendType='direct';
        this.sendUrl='';
    }

    start() {
    }

    stop() {
    }

    reset() {
    }

    buildPacket() {
    }

    sendPacket() {

    }

    send() {

    }

}


/*
export class ZuJsonEventSender extends ZuEventSender {
    constructor({dataUrl, deviceId, battery, period}) {
        super({dataUrl, deviceId, battery, period});

        this.xhr=null;
        this.sendQueue=[];
        this.loop=this.loop.bind(this);
        this.loopTimer=null;
        this.nextRequestId=1;
    }

    isRequestInProgress() {
        return false;
    }
    async sendJsonRpcRequest(url, method, params) {
        //return sleep(500);

        return new Promise( (resolve, reject) => {
            this.xhr = new window.XMLHttpRequest();
            let xhr=this.xhr;
            xhr.onreadystatechange = ()=>{
                if (xhr.readyState !== 4) return;
                if (xhr.status !== 200) {
                    reject(`http error status=${xhr.status}`);
                } else {
                    try {
                        let obj=JSON.parse(xhr.responseText);
                        if (obj.error) {
                            reject(`server error: ${obj.error.code} ${obj.error.message}`);
                        } else {
                            if (typeof(obj.result)!=='undefined') resolve(obj.result);
                            else reject('result field not found in server response');
                        }
                    } catch (e) {
                        reject('cannot parse server response');
                    }
                }
                xhr=undefined;
            };
            xhr.error=()=>{
                xhr=undefined;
                reject('http error');
            };
            xhr.open('post', url, true);
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            let request={
                jsonrpc: '2.0',
                method: method,
                params: params,
                id: this.nextRequestId++
            };
            xhr.send(JSON.stringify(request));
        });
    }

    loop() {
        if (this.isRequestInProgress()) return;
        let packet={
            'device-id': this.deviceId,
            'battery': this.battery,
            'period': this.period,
            'events': this.sendQueue.map( (eventRec)=> {
                eventRec.state='sending';
                return eventRec.event;
            })
        };
        if (packet.events.length===0) return;
        this.sendJsonRpcRequest(this.dataUrl, 'report', packet).then((res)=>{
            this.sendQueue.filter( (eventRec)=>eventRec.state==='sending' ).forEach( (eventRec)=> {
                zuEventListStore.dispatch(zuEventListActions.setSent(eventRec.eventId, (new Date()).toISOString()));
            } );
            this.sendQueue=this.sendQueue.filter( (eventRec)=>eventRec.state!=='sending' );
        }).catch((e)=>{
            this.sendQueue.filter( (eventRec)=>eventRec.state==='sending' ).forEach( (eventRec)=> {
                eventRec.state='none';
                zuEventListStore.dispatch(zuEventListActions.setSent(eventRec.eventId, 'error'));
            } );
        });
    }

    start() {
        //if (this.loopTimer) return;
        //this.loopTimer=setInterval(this.loop, 10000);
    }

    stop() {
        //if (!this.loopTimer) return;
        //clearInterval(this.loopTimer);
        //this.loopTimer=null;
    }

    reset() {
        this.sendQueue=[];
    }

    inSendQueue(eventId) {
        return this.sendQueue.reduce( (res, eventRec)=> {
            return res || eventRec.eventId===eventId;
        }, false);
    }

    send() {
        function filterFields(event) {
            let res=Object.assign({}, event);
            delete res.id;
            delete res.coord;
            delete res.latitude;
            delete res.longitude;
            delete res.sentTime;
            delete res.battery;
            delete res.period;
            delete res.deviceId;
            switch (res.type) {
                case ZuEventType.coord:
                    delete res.time;
                    delete res.reason;
                    delete res.source;
                    break;
                case ZuEventType.on:
                case ZuEventType.off:
                    delete res.source;
                    delete res.nmea;
                    break;
                case ZuEventType.signal:
                case ZuEventType.noSignal:
                    delete res.reason;
                    delete res.nmea;
                    break;
                case ZuEventType.lowBattery:
                case ZuEventType.removed:
                    delete res.reason;
                    delete res.source;
                    delete res.nmea;
                    break;
                default:
                    break;
            }
            return res;
        }

        let events=zuEventListStore.getState().filter((event)=>!event.sent && !this.inSendQueue(event.id));
        if (events.length===0) return;
        for (let t=events.length-1;t>=0;t--) {
            this.sendQueue.push({
                state: 'none',
                eventId: events[t].id,
                event: filterFields(events[t])
            });
        }
        this.loop();
    }

}
*/


export class ZuJsonEventSender2 extends ZuEventSender {
    constructor({dataUrl, deviceId, battery, period}) {
        super({dataUrl, deviceId, battery, period});

        this.xhr=null;
        this.sendQueue=[];
        this.nextRequestId=1;
    }

    isRequestInProgress() {
        return false;
    }


    async httpPost(url, data, contentType) {
        return new Promise( (resolve, reject) => {
            this.xhr = new window.XMLHttpRequest();
            let xhr=this.xhr;
            xhr.onreadystatechange = ()=>{
                if (xhr.readyState !== 4) return;
                if (xhr.status !== 200) {
                    reject(`http error status=${xhr.status}`);
                } else {
                    try {
                        let obj=JSON.parse(xhr.responseText);
                        if (obj.error) {
                            reject(`server error: ${obj.error.code} ${obj.error.message}`);
                        } else {
                            if (typeof(obj.result)!=='undefined') resolve(obj.result);
                            else reject('result field not found in server response');
                        }
                    } catch (e) {
                        reject('cannot parse server response');
                    }
                }
                this.xhr=undefined;
            };
            xhr.error=()=>{
                this.xhr=undefined;
                reject('http error');
            };
            xhr.open('post', url, true);
            xhr.setRequestHeader("Content-Type", contentType); //"text/plain;charset=UTF-8");
            xhr.send(data);
        });
    }

    sendJsonRpcRequest(url, method, params) {
        let request={
            jsonrpc: '2.0',
            method: method,
            params: params,
            id: this.nextRequestId++
        };
        return this.httpPost(url, JSON.stringify(request), 'application/json;charset=UTF-8');
    }

    sendPacket(packet) {
        if (this.xhr) return;
        if (packet.events.length===0) return;

        let sendFnc;
        switch (this.sendType) {
            case 'direct':
                switch (this.packetType) {
                    case 'json':
                        sendFnc=this.sendJsonRpcRequest.bind(this, config.zu.url["zu-api-json"]+packet.queryString(), 'report', packet.asObject());
                        break;
                    case 'plainText':
                        sendFnc=this.httpPost.bind(this, config.zu.url["zu-api-plain"]+packet.queryString(), packet.asPlainText(), "text/plain;charset=UTF-8");
                        break;
                }
                break;
            case 'url-browser':
                switch (this.packetType) {
                    case 'json':
                        sendFnc=this.sendJsonRpcRequest.bind(this, this.sendUrl+packet.queryString(), 'report', packet.asObject());
                        break;
                    case 'plainText':
                        sendFnc=this.httpPost.bind(this, this.sendUrl+packet.queryString(), packet.asPlainText(), "text/plain;charset=UTF-8");
                        break;
                }
                break;
            case 'url-server':
                switch (this.packetType) {
                    case 'json':
                        sendFnc=this.sendJsonRpcRequest.bind(this, config.zu.url["zu-api-proxy"], 'proxy', {
                                url: this.sendUrl,
                                query: packet.queryString(),
                                contentType: 'application/json;charset=UTF-8',
                                data: JSON.stringify({
                                    jsonrpc: '2.0',
                                    method: 'report',
                                    id: this.nextRequestId++,
                                    params: packet.asObject()
                                })
                            }
                        );
                        break;
                    case 'plainText':
                        sendFnc=this.sendJsonRpcRequest.bind(this, config.zu.url["zu-api-proxy"], 'proxy', {
                            url: this.sendUrl,
                            query: packet.queryString(),
                            contentType: 'text/plain;charset=UTF-8',
                            data: packet.asPlainText()
                        });
                        break;
                }
                break;
        }
        sendFnc().then(()=>{
            packet.eventIds().forEach( (eventId)=>zuEventListStore.dispatch(zuEventListActions.setSent(eventId, (new Date()).toISOString())) );
        }).catch(()=>{
            packet.eventIds().forEach( (eventId)=>zuEventListStore.dispatch(zuEventListActions.setSent(eventId, 'error')) );
        });
    }

    send() {
       let events=zuEventListStore.getState().filter((event)=>!event.sent);
        let pkt=new ZuPacket({deviceId: this.deviceId, battery: this.battery, period: this.period});
        if (events.length===0) return;
        for (let t=events.length-1;t>=0;t--) pkt.addEvent(events[t]);
        //events.forEach( (event)=>pkt.addEvent(event) );
        let str=pkt.asPlainText();
        let pkt2=new ZuPacket({});
        pkt2.fromPlainText(str);
        this.sendPacket(pkt);
    }

}
