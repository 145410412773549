import React from "react";
import {Placemark, Polyline} from "react-yandex-maps";
import {ZuState} from "../lib/ZuState";
import {ZuEventType, ZuSignalSource} from "../lib/ZuPoint";


export default function DcPolylines({points, onPlacemarkClick}) {
    let lines=[];

    function zuLines(events) {
        let zuState=new ZuState();
        //zuState.resetGsmByCoord=true;
        zuState.resetSatByCoord=true;
        let lastCoordPoint;
        let waitingSignalPointIndex=null;

        function placemark(event, key) {
            return (<Placemark key={key}
                       geometry={lastCoordPoint.coord}
                       options={{preset: zuState.getEventIconType(event), iconColor: zuState.getEventColor(event)}}
                       properties={{hintContent: zuState.getEventHint(event)}}
                       onClick={() => { if (onPlacemarkClick) onPlacemarkClick(key); } }
            />);
        }

        return events.reduce( (components, event, index) => {
            //if (!zuState.isPowerOn) debugger;
            let updateZuState=true;
            //if (event.type!==ZuEventType.coord) debugger;
            switch (event.type) {
                case ZuEventType.coord:
                    if (lastCoordPoint) {
                        //console.log("push polyline ", lastCoordPoint.coord, event.coord, zuState.getLineColor(), zuState.getLineStyle())
                        components.push(
                            <Polyline key={index}
                                      geometry={[ lastCoordPoint.coord, event.coord ]}
                                      options={{
                                          strokeColor: zuState.getLineColor(),
                                          strokeStyle: zuState.getLineStyle(),
                                          strokeWidth: 3
                                      }}
                            />
                        );
                    }
                    lastCoordPoint=event;
                    if (waitingSignalPointIndex!==null) {
                        components.push(placemark(events[waitingSignalPointIndex], waitingSignalPointIndex));
                        zuState.updateState(events[waitingSignalPointIndex]);
                        waitingSignalPointIndex=null;
                    }
                    break;
                case ZuEventType.on:
                    waitingSignalPointIndex=index;
                    updateZuState=false;
                    break;

                case ZuEventType.signal:
                    if (event.source===ZuSignalSource.sat) {
                        waitingSignalPointIndex=index;
                        updateZuState=false;
                    } /*else if (event.source===ZuSignalSource.gsm) {
                        components.push(placemark(event, index));
                    }*/ else {
                        components.push(placemark(event, index));
                    }
                    break;
                    /*
                case ZuEventType.signal:
                    if (event.source===ZuSignalSource.sat) {
                        waitingSignalPointIndex=index;
                        updateZuState=false;
                    } else {
                        components.push(placemark(event, index));
                    }
                    break;
                    */


                default:
                /*case ZuEventType.off:
                case ZuEventType.lowBattery:
                case ZuEventType.signal:
                case ZuEventType.noSignal:
                case ZuEventType.removed:*/
                    components.push(placemark(event, index));
                    break;
            }
            if (updateZuState) zuState.updateState(event);
            return components;
        }, []);
    }

    for (let deviceId of Object.keys(points)) {
        let newLine=zuLines(points[deviceId], deviceId);
        if (newLine) lines.push(newLine);
    }
    //console.log("lines", lines)

    return lines;
};
