const reducer = function(state = [], action) {
    switch (action.type) {
        case 'events.clear':
            return [];
        case 'events.add':
            return [{
                ...action.event,
               sentTime: null
            },
            ...state];
        case 'events.setSent':
            return state.map( (event)=> {
                if (event.id===action.eventId) return {...event, sent: action.value};
                return event;
            });
        case 'events.markAllSent':
            return state.map( (event)=>({ ...event, sent: Date.now()}) );
        case 'events.stopNotSent':
            return state.map( (event)=>({
                    ...event,
                    sent: event.sent || 'stopped'
                }));
        default:
            return state;
    }
};

export default reducer;