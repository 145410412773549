import React from "react";
import Typography from "@material-ui/core/Typography";



function strongTrue(value) {
    return value?<strong>Да</strong>:'Нет';
}

function strongFalse(value) {
    return value?'Да':<strong>Нет</strong>;
}


export function ZuStateComponent({zuState}) {
    let items=[];
    items.push([`Питание`, strongFalse(zuState.isPowerOn)]);
    items.push([`GSM`, strongFalse(!zuState.noSignalGsm)]);
    items.push([`Спутники`, strongFalse(!zuState.noSignalSat)]);
    items.push([`Удалено`, strongTrue(zuState.isRemoved)]);
    items.push([`Разряд`, strongTrue(zuState.lowBattery)]);

    return (
        <Typography>
            <table>
                {
                    items.map( (item)=><tr><td>{item[0]}</td><td>{item[1]}</td></tr> )
                }
            </table>
        </Typography>
    );
}

