let alphabit='abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789001234567890';

export function randomChar() {
    return alphabit[Math.floor(Math.random()*alphabit.length)];
}

export function randomString(length) {
    let res='';
    while (res.length<length) res+=randomChar();
    return res;
}