
const isProduction=(process.env.NODE_ENV === 'production');


//const productionHost=`zutester.mooo.com`;
//const productionBaseUrl=`https://${productionHost}`;

const config=Object.freeze({
    'zu': Object.freeze({
        'url': Object.freeze({
            'api': '/api',
            'auth': '/auth',
            //'zu-api-json': 'http://localhost:5555/test',
            'zu-api-json': '/zu/data/json',
            'zu-api-proxy': '/zu/data/proxy',
            //'zu-api-json': isProduction?`${productionBaseUrl}/zu/data/json`:'http://localhost:3000/zu/data/json',
            //'zu-api-json': isProduction?`${productionBaseUrl}/zu/data/json`:'https://localhost:5001/zu/data/json',

            'zu-api-plain': '/zu/data/plain'
            //'zu-api-plain': isProduction?`${productionBaseUrl}/zu/data/plain`:'http://localhost:3000/zu/data/plain'
            //'zu-api-plain': isProduction?`${productionBaseUrl}/zu/data/plain`:'http://localhost:3000/zu/data/plain'
        }),
        'ui': Object.freeze({
            'ymaps': Object.freeze({
                'default_zoom': 15,
                'default_center': [55.75, 37.57],
                'center_duration': 500,
                'modules': ["package.full", "coordSystem.geo"],
                'controls': ['zoomControl', 'fullscreenControl', 'geolocationControl', 'rulerControl', 'typeSelector']
            })
        })
    }),
    'dc': Object.freeze({
        'url': Object.freeze({
            //'api': '/api',
            //'ws': `wss://${document.location.host}/dc/ws`
            'ws': isProduction?`wss://${document.location.host}/dc/ws`:`wss://${document.location.hostname}:5001/dc/ws`
            //'ws': isProduction?`wss://${productionHost}/dc/ws`:'wss://localhost:5001/dc/ws'
        }),
        'ui': Object.freeze({
            'ymaps': Object.freeze({
                'default_zoom': 15,
                'default_center': [55.75, 37.57],
                'center_duration': 500,
                'modules': ["package.full", "coordSystem.geo"],
                'controls': ['zoomControl', 'fullscreenControl', 'geolocationControl', 'rulerControl', 'typeSelector']
            })
        })
    })
});

export default config;

//module.exports=config;