import {ZuEventType, zuEventTypeDisplayName, ZuSignalSource} from "./ZuPoint";

function round10000(value) {
    return Math.round(value*10000)/10000;
}

function strongTrue(value) {
    return value?'<strong>Да</strong>':'Нет';
}

function strongFalse(value) {
    return value?'Да':'<strong>Нет</strong>';
}


export class ZuState {
    constructor () {
        this.reset();
        this.resetGsmByCoord=false;
        this.resetSatByCoord=false;
    }

    getState() {
        return {
            lastCoord: [...this.lastCoord],
            noSignalGsm: this.noSignalGsm,
            noSignalSat: this.noSignalSat,
            isRemoved: this.isRemoved,
            isPowerOn: this.isPowerOn,
            lowBattery: this.lowBattery
        };
    }
    reset() {
        this.lastPosition = [null, null];
        this.noSignalGsm = false;
        this.noSignalSat = false;
        this.isRemoved = false;
        this.isPowerOn=true;
        this.lowBattery=false;
    }

    updateState(event) {
        if (!event) return;
        if (event.coord && event.coord[0]!==null) this.lastCoord=event.coord;
        if (event.type===ZuEventType.coord) {
            if (this.resetGsmByCoord) this.noSignalGsm=false;
            if (this.resetSatByCoord) this.noSignalSat=false;
            return;
        }
        this.noSignalGsm=((event.type===ZuEventType.noSignal) && (event.source===ZuSignalSource.gsm)) || false;
        this.noSignalSat=((event.type===ZuEventType.noSignal) && (event.source===ZuSignalSource.sat)) || false;
        this.isRemoved=this.isRemoved || event.type===ZuEventType.removed || false;
        this.lowBattery=this.lowBattery || event.type===ZuEventType.lowBattery || false;
        this.isPowerOn=(event.type===ZuEventType.on)?true: ( (event.type===ZuEventType.off)?false:this.isPowerOn );
    }

    getLineStyle() {
        if (this.noSignalSat) return '2 2';
        return '';
    }

    getEventHint(event, zuStateName='Входящее состояние') {
        let items=[];
        items.push([`<strong>Позиция</strong>`, '']);
        if (event.coord && event.coord[0]!==null) items.push(['Координаты', `${round10000(event.coord[0])}/${round10000(event.coord[1])}`]);
        if (event.nmea) items.push(['NMEA', event.nmea]);
        if (event.time) items.push([`Время`, event.time]);
        items.push([`<strong>Событие</strong>`, '']);
        items.push([`Тип`, zuEventTypeDisplayName(event.type)]);
        if (event.type===ZuEventType.noSignal) items.push([`Сигнал`, event.source]);
        if (event.type===ZuEventType.on || event.type===ZuEventType.off) items.push([`Причина`, event.reason]);
        items.push([`<strong>${zuStateName}</strong>`, '']);
        items.push([`Питание`, strongFalse(this.isPowerOn)]);
        items.push([`GSM`, strongFalse(!this.noSignalGsm)]);
        items.push([`Спутники`, strongFalse(!this.noSignalSat)]);
        items.push([`Удалено`, strongTrue(this.isRemoved)]);
        items.push([`Разряд`, strongTrue(this.lowBattery)]);

        return `<table>` +
            items.map( (item)=>`<tr><td>${item[0]}</td><td>${item[1]}</td></tr>` ).join('')+
            `</table>`;
    }

    getEventIconType(event) {
        switch (event.type) {
            case ZuEventType.on:
            case ZuEventType.signal:
                return "islands#circleDotIcon";
            default:
                return "islands#circleIcon";
        }
    }


    getLineColor() {
        if (!this.isPowerOn) return "#000000";
        if (this.isRemoved) return "#FF0000";
        if (this.noSignalSat) return '#808080';
        if (this.noSignalGsm) return '#7f3c82';
        return "#0011aaaa";
    }

    getEventColor(event) {
        switch (event.type) {
            case ZuEventType.coord:
                return '#0066ffff';
            case ZuEventType.on:
                return '#18750d';//'#9a592e';
            case ZuEventType.off:
                return '#000000';
            case ZuEventType.signal:
            case ZuEventType.noSignal:
                if (event.source===ZuSignalSource.sat) return '#808080';
                else if (event.source===ZuSignalSource.gsm) return '#7f3c82';
                break;
            case ZuEventType.removed:
                return 'red';
            case ZuEventType.lowBattery:
                return '#a2311e';
            default:
                return '#0066ffff';
        }
    }

}

