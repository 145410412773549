


const defAlphabet=  '!"#$%&\'()*+,-./01234'+
                    '56789:;<=>?@ABCDEFGH'+
                    'IJKLMNOPQRSTUVWXYZ[\\'+
                    ']^_`abcdefghijklmnop'+
                    'qrstuvwxyz{|}~'+
                    ' \t\r\n';

function getRandom(p, a, c) {
    let xn=a;
    return function () {
        xn=( xn * ( a + 1 ) + c) % p;
        return xn / p;
    }
}

function getPermutation(p, s, alphabet=defAlphabet) {
    let chars=alphabet;
    let res='';
    let a=s % 1000;
    let c=Math.floor(s / 1000) % 1000;
    let random=getRandom(p, a, c);
    while (chars.length) {
        let index=Math.floor(random()*chars.length);
        res+=chars[index];
        chars=chars.slice(0, index)+chars.slice(index+1);
    }
    return res;
}


function mask(msg, perm, alphabet=defAlphabet) {
    let res='';
    let offset=0;
    for (let t=0;t<msg.length;t++) {
        let ch=msg[t];
        let index= ( alphabet.indexOf(ch) + offset++ ) % alphabet.length;
        res+=perm[index];
    }
    return res;
}

function unmask(msg, perm, alphabet=defAlphabet) {
    let res='';
    let offset=0;
    for (let t=0;t<msg.length;t++) {
        let ch=msg[t];
        let index=( perm.indexOf(ch) + alphabet.length - offset++ ) % alphabet.length;
        res+=alphabet[index];
        if (offset===alphabet.length) offset=0;
    }
    return res;
}

function getChecksum(msg) {
    let xor=0;
    for (let t=0;t<msg.length;t++) xor^=msg.charCodeAt(t);
    xor=xor.toString(16).toUpperCase();
    if (xor.length===1) xor='0'+xor;
    return xor;
}


module.exports={
    getPermutation, mask, unmask, getChecksum
};

/*
let perm=getPermutation(3457, 402291);
let msg='some text message';
let maskedMsg=mask(msg, perm);
let unmaskedMsg=unmask(maskedMsg, perm);
console.log('-------------------------');
console.log('perm:', perm);
console.log('masked:', maskedMsg);
console.log('checksum:', getChecksum(maskedMsg));
console.log('unmasked:', unmaskedMsg);
console.log('-------------------------');
*/

